<template>
  <div class="mb-6">
    <h2 v-if="!editMode" class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
      Create new Inventory
    </h2>
    <h2 v-else class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
      Edit Inventory
    </h2>
    <div
      class="flex flex-col w-full p-4 space-y-4 bg-white border border-gray-200 shadow-lg md:w-3/4 dark:bg-gray-700 dark:border-gray-500 md:p-8 rounded-xl">
      <div class="w-full">
        <label class="">
          <span class="label">Select product for inventory</span>
          <select v-model="$v.inventory.product_id.$model" class="input">
            <option value="" selected>Select product</option>
            <option v-for="product in products" :key="product.id" :value="product.id">
              {{ product.name }}
            </option>
          </select>
        </label>
      </div>
      <div class="w-full">
        <label class="">
          <span class="label">Total</span>
          <input type="number" class="input" placeholder="total" v-model="$v.inventory.total.$model" />
        </label>
      </div>
      <div class="flex justify-center w-full">
        <button class="btn w-36" :class="$v.$invalid ? 'bg-gray-600 text-white' : null"
          @click="editMode ? updateInventory() : saveInventory()">
          {{ editMode ? "Update Inventory" : "Create Inventory" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import isNil from "ramda/es/isNil";
export default {
  name: "CreateproductComponent",
  mixins: [validationMixin],
  data() {
    return {
      inventory: {
        product_id: '',
        total: '',
      },
    };
  },
  mounted() {
    this.loadProducts();
    if (this.editMode) {
      this.loadInventory();
    }
  },
  computed: {
    ...mapGetters({
      products: ["product/getInventoryProducts"],
      inv: ["inventory/getInventory"],
    }),
    editMode() {
      return !isNil(this.$route.params) && !isNil(this.$route.params.id);
    },
  },
  validations: {
    inventory: {
      product_id: {
        required,
      },
      total: {
        required,
      },
    },
  },
  methods: {
    loadInventory() {
      this.$store.dispatch("inventory/loadInventory", {
        id: this.$route.params.id,
      });
    },
    loadProducts() {
      this.$store.dispatch("product/getProducts");
    },
    saveInventory() {
      if (!this.$v.$invalid) {
        this.$store.dispatch("inventory/storeInventory", { inventory: this.inventory });
      }
    },
    updateInventory() {
      if (!this.$v.$invalid) {
        this.$store.dispatch("inventory/updateInventory", {
          id: this.$route.params.id,
          inventory: this.inventory,
        });
      }
    },
  },
  watch: {
    inv: {
      handler() {
        if (!isNil(this.inv) && this.editMode) {
          this.inventory = { ...this.inv };
        }
      },
      immediate: true,
    },
  },
};
</script>

<style>

</style>
